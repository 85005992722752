/**
import Outfit font from google fonts

*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overscroll-behavior: none;
  background-color: black;
}

#root {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overscroll-behavior: none;
  overflow: hidden;
}
/** mobile */
@media screen and (max-width: 999px) {
  .home-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw;
    background-color: black;
  }

  .left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50vh;
    width: 100vw;
    background-color: black;
    position: relative;
    padding-left: 3vw;
  }

  .right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100vw;
    background-color: black;
  }

  .home-text {
    font-family: "Outfit";
    font-size: 10vh;
    color: white;
    font-weight: bold;
    transform: rotate(-19.96deg);
    line-height: 9vh;
    pointer-events: none;
    user-select: none;
    position: absolute;
    bottom: 0;
    top: 0;
    margin-left: 6vw;
  }
  .mail-text {
    font-family: "Outfit";
    color: white;
    font-size: 2vh;
  }
}

/** desktop */
@media screen and (min-width: 1000px) {
  .home-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw;
    background-color: black;
  }

  .left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 50vw;
    background-color: black;
    position: relative;
    margin-left: 2vw;
  }

  .right-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100vh;
    width: 50vw;
    background-color: black;
  }

  .mail-text {
    font-family: "Outfit";
    color: white;
    font-size: 2vh;
    margin-right: 10vw;
    margin-bottom: 10vw;
  }

  .home-text {
    font-family: "Outfit";
    font-size: 20vh;
    color: white;
    font-weight: bold;
    transform: rotate(-19.96deg);
    line-height: 18vh;
    pointer-events: none;
    user-select: none;
    position: absolute;
    left: 0;
  }
}

.contentWithSequence {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.sequence-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sect1 {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sect2 {
  background-color: #8c6868;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sect3 {
  background-color: #322929;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sect4 {
  background-color: #f19191;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sect5 {
  background-color: #994141;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sect6 {
  background-color: #4d2626;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.The-blur {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  height: 20px;
  width: 100px;
}

.Next-blur {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  height: 20px;
  width: 100px;
}

.Big-blur {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  height: 20px;
  width: 100px;
}

.Thing-blur {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  height: 20px;
  width: 100px;
}

.HeroSection {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.The {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.Next {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.Big {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.Thing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.linkedin-logo {
  height: 2vh;
  width: 2vh;
}
